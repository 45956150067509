<template>
  <div class="actions">
    <div class="content-layout-fixer">
      <BrandButton
        class="tab-button"
        :size="sizes.xSmall"
        :outlined="!options.leftItem.isActive"
        :target="options.leftItem.route"
        as="router-link"
      >
        <VueText
          :color="options.leftItem.isActive ? 'white-100' : 'currentColor'"
          sizeLevel="4"
          weightLevel="3"
          >{{ options.leftItem.label }}</VueText
        >
      </BrandButton>
      <BrandButton
        class="tab-button"
        :size="sizes.xSmall"
        :outlined="!options.rightItem.isActive"
        :target="options.rightItem.route"
        as="router-link"
      >
        <VueText
          :color="options.rightItem.isActive ? 'white-100' : 'currentColor'"
          sizeLevel="4"
          weightLevel="3"
          >{{ options.rightItem.label }}</VueText
        >
      </BrandButton>
    </div>
  </div>
</template>
<script>
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants';

export default {
  name: 'BrandTwinTabs',
  data() {
    return {};
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  components: {
    BrandButton,
    VueText,
  },
  computed: {
    icon() {
      return {
        sheetLines: ICON_VARIABLES.sheetLines,
        speechBubble: ICON_VARIABLES.speechBubble,
      };
    },
    sizes() {
      return COMPONENT_SIZES;
    },
  },
};
</script>
<style scoped lang="scss">
.actions {
  height: unset !important;
  background: transparent !important;
  .content-layout-fixer {
    display: flex;
    padding: palette-space-level(20);
    padding-bottom: palette-space-level(10);
    z-index: 5;
  }
  /deep/ .tab-button {
    display: block !important;
    width: unset !important;
    background: transparent !important;
    min-height: unset !important;
    padding: 0;
    margin-right: palette-space-level(40);
    cursor: pointer;
    span,
    span p {
      font-size: palette-font-size-level('5');
      font-weight: palette-font-weight-level('3');
      color: palette-color('grey-30');
    }
    &::after {
      content: '';
      border-radius: palette-radius('radius-11');
      z-index: 2;
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: -13px;
      left: 0;
      background: linear-gradient(to left, #d20051 0%, #ffa300 100%);
      opacity: 0;
      transition: all 0.3s ease;
    }
    &.router-link-active {
      position: relative;
      span,
      span p {
        color: palette-color('grey-40');
      }
      &::after {
        opacity: 1;
      }
    }
  }
}
</style>
